import { Dropdown, InputField, MemoField, RadioField } from '../components/controls'

export const defaultCreditCardState = () => ({
    creditCard: 'Chase Ink Card',
    customerJob: 'mz',
    payee: '',
    account: 'A/R:A/R - MZ',
    amt: '',
    feePaid: 0, // Always 0
    feeCharged: 0,
    markup: 8,
    memo: '',
})

const CreditCard = ({
    state, setState, options
}) => {
    return (
        <>
            <Dropdown
                label="Credit Card"
                selected={state?.creditCard}
                options={options.creditCards ?? []}
                onChange={e => setState({...state, creditCard: e.target.value })}
            />

            <InputField type="date"
                label="Date:"
                value={state.date}
                defaultValue={state.date}
                placeholder={state.date}
                onChange={e => setState({...state, date: e.target.value })}
            />
            
            <Dropdown
                label="Customer Job"
                selected={state?.customerJob}
                options={options.customers ?? []}
                onChange={e => setState({...state, customerJob: e.target.value })}
            />
            
            <Dropdown
                label="Payee"
                selected={state?.payee}
                options={options.payees ?? []}
                onChange={e => setState({...state, payee: e.target.value })}
            />
            
            <Dropdown
                label="Account"
                selected={state?.account}
                options={options.accounts ?? []}
                onChange={e => setState({ ...state, account: e.target.value })}
            />

            <InputField
                label="Amount $"
                value={state.amt}
                placeholder="0.00"
                type="number"
                step="0.01"
                onChange={e => setState({ ...state, amt: e.target.value })}
            />

            <InputField
                label="Markup %"
                value={state.markup}
                placeholder="0"
                type="number"
                onChange={e => setState({ ...state, markup: e.target.value })}
            />

            <RadioField
                label="Fee Charged $"
                options={[
                    { value: 0, label: "$0" },
                    { value: 45, label: "$45" },
                ]}
                value={state.feeCharged}
                setValue={val => setState({...state, feeCharged: parseInt(val)})}
                hasOther
            />

            <MemoField
                label="Memo"
                value={state.memo}
                placeholder="Enter transaction description"
                type="text"
                onChange={e => setState({ ...state, memo: e.target.value })}
            />


        </>
        );
    }
    
    export default CreditCard