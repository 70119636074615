class TxnRow {
    constructor() {
        this.submitTime = ''
        this.txType = ''
        this.txDate = ''
        this.bank = ''
        this.cc = ''
        this.custJob = ''
        this.payee = '' // Receiver or Sender (for deposits)
        this.account = ''
        this.amt = 0
        this.feePaid = 0
        this.feeCharged = 0
        this.markupPct = 0
        this.memo = ''
    }

    asRow() {
        if (this.err) {
            return [[], this.err]
        }
        const row = [
            this.submitTime,
            this.txType,
            this.txDate,
            this.bank,
            this.cc,
            this.custJob,
            this.payee,
            this.account,
            this.amt,
            this.feePaid,
            this.feeCharged,
            this.markupPct,
            this.memo,
        ]
        return [row, null]
    }

    processState(state) {
        this.submitTime = new Date().toISOString()
        this.txDate = state.date
        this.txType = state.txType
        this.amt = state.amt
        this.memo = state.memo
        switch (this.txType) {
            case 'deposit':
                this.setDepositColumns(state)
                break
            case 'check':
                this.setCheckColumns(state)
                break
            case 'credit':
                this.setCreditColumns(state)
                break
            default:
                this.err = `invalid txType of ${this.txType}`
                return;
        }
    }

    setDepositColumns(state) {
        this.bank = state.bankAccount
        this.payee = state.payee
        this.account = state.account
        this.feePaid = state.feePaid
        this.feeCharged = state.feePaid // Note for deposit it's the same
    }
    
    setCheckColumns(state) {
        this.account = state.account
        this.bank = state.bankAccount
        this.custJob = state.customerJob
        this.markupPct = state.markup
        this.payee = state.payee
        this.feePaid = state.feePaid
        this.feeCharged = state.feeCharged
    }
    
    setCreditColumns(state) {
        this.account = state.account
        this.cc = state.creditCard
        this.custJob = state.customerJob
        this.markupPct = state.markup
        this.payee = state.payee
        this.feePaid = state.feePaid
        this.feeCharged = state.feeCharged
    }
}


export const convertToRows = (state) => {
    const txRow = new TxnRow()
    txRow.processState(state)
    const [row, err] = txRow.asRow()
    return [row, err]
}