import { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from 'js-cookie'

const APITokenInput = () => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(Cookies.get('apiKey') || '')
    }, [])

    const handleChange = (event) => {
        setValue(event.target.value)
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        Cookies.set('apiKey', value, { secure: true, sameSite: 'strict' })
        console.log('Saved API Key to cookies')
    };

    const handleRemove = event => {
        event.preventDefault();
        setValue('')
        Cookies.remove('apiKey')
    }

    return (
        <Form onSubmit={handleSubmit}>
        <Label>API-Key:</Label>
        <input type="text" value={value} onChange={handleChange} />
        <button type="submit">Save</button>
        <button type="remove" onClick={handleRemove}>Remove</button>
        </Form>
    );
};

const Form = styled.form`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  padding: 20px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  button {
    margin-left: 10px;
  }
`

const Label = styled.div`
  margin-right: 20px;
`

export default APITokenInput