import { Dropdown, InputField, MemoField, RadioField } from '../components/controls'

export const defaultDepositsState = () => ({
    bankAccount: 'Banks:CHASE BANK',
    payee: 'Zaman Bhai',
    account: "A/R:A/R - MZ",
    amt: "",
    feePaid: 0,
    // feeCharged is always same as feePaid
    memo: "",
})

const Deposit = ({
    state, setState, options
}) => {
    return (
        <>
            <Dropdown
                label="Bank Account"
                selected={state?.bankAccount}
                options={options.banks ?? []}
                onChange={e => setState({...state, bankAccount: e.target.value })}
            />

            <InputField type="date"
                label="Date:"
                value={state.date}
                defaultValue={state.date}
                placeholder={state.date}
                onChange={e => setState({...state, date: e.target.value })}
            />
            
            <Dropdown
                label="Received From"
                selected={state?.payee}
                options={options.payees ?? []}
                onChange={e => setState({...state, payee: e.target.value })}
            />
            
            <Dropdown
                label="Account"
                selected={state?.account}
                options={options.accounts ?? []}
                onChange={e => setState({ ...state, account: e.target.value })}
            />

            <InputField
                label="Amount $"
                value={state.amt}
                placeholder="0.00"
                type="number"
                step="0.01"
                onChange={e => setState({ ...state, amt: e.target.value })}
            />

            <RadioField
                label="Deposit Fee $"
                options={[
                    { value: -0, label: "$0" },
                    { value: -15, label: "$15" },
                ]}
                value={state.feePaid}
                setValue={val => setState({...state, feePaid: parseInt(val)})}
                hasOther
            />

            <MemoField
                label="Memo"
                value={state.memo}
                placeholder="Enter transaction description"
                type="text"
                onChange={e => setState({ ...state, memo: e.target.value })}
            />

        </>
    );
}

export default Deposit