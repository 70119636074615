import styled from 'styled-components'
import { useEffect, useState } from 'react';

import logo from './img/argologo.svg';
import './App.css';
import APITokenInput from './components/ApiTokenInput'
import Form from './Form'
import { initTokenClient, loadGIS, renderGoogleButton, revokeAccessToken } from './scripts/login';
// import { sample_token } from './.private_data'; // DEBUG

function App() {
  const [user, setUser] = useState({})
  // const [user, setUser] = useState({...sample_token}) // DEBUG

  const signOut = () => {
    if (user?.access_token) {
      revokeAccessToken(user?.access_token)
    }
    setUser({})
  }

  useEffect(() => {
    const fn = async () => {
      let userToken = user?.user_token
      if (!userToken) {
        userToken = await loadGIS()
      }
      initTokenClient(userToken, setUser)
    }
    fn()
  }, [])

  useEffect(() => {
    renderGoogleButton(user)
  }, [user])

  return (
    <Container>
      <Header>
        <APITokenInput />
        <Logo src={logo} alt="Argonauts USA Inc" />
        <div id="signInDiv"></div>
        {user?.email && (
          <SignIn>
            <div>
              Signed in as <i>{user.email}</i>
              <TextLink onClick={signOut}>Sign out</TextLink>
            </div>
            <img src={user.picture} alt="user_photo" />
        </SignIn>)}
        <Form user={user} />
      </Header>
    </Container>
  );
}




/* #region Styles */

const Logo = styled.img`
  height: 160px;
  margin-bottom: 10px;
`

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto 10px auto;
  text-align: center;
`
const Header = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`

const SignIn = styled.div`
  display: flex;
  align-items: center;
  text-align:right;
  font-size: 12pt;
  position: fixed;
  top: 0;
  right: 0;
  height: 80px;
  div {
    width: 200px;
    margin-right:10px;
  }
  img {
    height: 80px;
  }
`

const TextLink = styled.div`
  :hover {
    text-decoration: underline;
    color: blue;
  }
`

/* #endregion Styles */


export default App;
