/*
 * Update this template with the right information and save it as `.private_data.js`
 */


export const CLIENT_ID = '214013373513-f0m7tqrhaoparbsf72b16nmv9d71oogj.apps.googleusercontent.com'
export const SPREADSHEET_ID = '1p96TbqNltBp79FSOqNc3V4Qpzfm9_YrHa_jDegfZc0U'
export const APP_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbz-BIahpr3VD0Jz1Q5pOb0MrqK8_SyXMtleDa67XzS2E0XOrsBVsWrVaV-rX7pJ0b6DeA/exec'

// /** DO NOT SHARE!!! */
// export const sample_token = {} /* ENTER SAMPLE TOKEN FROM GOOGLE IDENTITY SERVICE LOGIN */