import { Dropdown, InputField, MemoField, RadioField } from '../components/controls'

export const defaultCheckState = () => ({
    bankAccount: 'Banks:CHASE BANK',
    customerJob: 'MZ',
    payee: 'OTHER',
    account: 'A/R:A/R - MZ',
    amt: '',
    feePaid: 5,
    feeCharged: 55,
    markup: 5,
    memo: '',
})

const Check = ({
    state, setState, options,
}) => {
    return (
        <>
            <Dropdown
                label="Bank Account"
                selected={state?.bankAccount}
                options={options.banks ?? []}
                onChange={e => setState({...state, bankAccount: e.target.value })}
            />

            <InputField type="date"
                label="Date:"
                value={state.date}
                defaultValue={state.date}
                placeholder={state.date}
                onChange={e => setState({...state, date: e.target.value })}
            />
            
            <Dropdown
                label="Customer Job"
                selected={state?.customerJob}
                options={options.customers ?? []}
                onChange={e => setState({...state, customerJob: e.target.value })}
            />

             <Dropdown
                label="Payee"
                selected={state?.payee}
                options={options.payees ?? []}
                onChange={e => setState({...state, payee: e.target.value })}
            />
            
            <Dropdown
                label="Account"
                selected={state?.account}
                options={options.accounts ?? []}
                onChange={e => setState({ ...state, account: e.target.value })}
            />

            <InputField
                label="Amount $"
                value={state.amt}
                placeholder="0.00"
                type="number"
                step="0.01"
                onChange={e => setState({ ...state, amt: e.target.value })}
            />

            <InputField
                label="Markup %"
                value={state.markup}
                placeholder="5"
                type="number"
                onChange={e => setState({ ...state, markup: e.target.value })}
            />
            
            <RadioField
                label="Fee Paid $"
                options={[
                    { value: 0, label: "$0 (None)" },
                    { value: 5, label: "$5 (FX)" },
                    { value: 30, label: "$30 (USD)" },
                ]}
                value={state.feePaid}
                setValue={val => setState({...state, feePaid: parseInt(val)})}
            />

            <RadioField
                label="Fee Charged $"
                options={[
                    { value: 0, label: "$0 (None)" },
                    { value: 30, label: "$30" },
                    { value: 55, label: "$55 (USD/FX)" },
                ]}
                value={state.feeCharged}
                setValue={val => setState({...state, feeCharged: parseInt(val)})}
            />

            <MemoField
                label="Memo"
                value={state.memo}
                placeholder="Enter transaction description"
                type="text"
                onChange={e => setState({ ...state, memo: e.target.value })}
            />
        </>
    );
}
    
export default Check