import jwt_decode from 'jwt-decode'
import { CLIENT_ID } from '../.private_data'

export const SCOPES = "https://www.googleapis.com/auth/spreadsheets" // GOOGLE SHEETS

/* Authorize API  - BROKEN! */
export const initTokenClient = (user_token, setUser) => {
    const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        // prompt: 'consent',
        callback: tokenResponse => {
            setUser(user => ({ ...user, gapi_token: tokenResponse }))
            return tokenResponse;
        }
    })

    setUser({ ...user_token, tokenClient })
}

export async function loadGIS() {
    /* global google */
    return new Promise((resolve, reject) => {
        google.accounts.id.initialize({
            client_id: CLIENT_ID,
            callback: resolve,
            onerror: reject,
            ontimeout: reject,
            timeout: 10000, // 10 seconds.
            retry: true,
        });
    }).then(gis => {
        const user_token = gis?.credential && jwt_decode(gis.credential)
        console.log('USER_TOKEN:', user_token);
        return user_token
    });
}

export function renderGoogleButton(user) {
    const elem = document.getElementById("signInDiv")
    if (!user?.email) {
        google.accounts.id.renderButton(
            elem,
            {theme: "filled_blue", size: "medium"}
        );
    } else {
        elem.hidden = true;
    }
}

export function revokeAccessToken(token) {
    /* global gapi */
    const gApiToken = gapi?.client?.getToken();
    if (gApiToken) {
        google.accounts.oauth2.revoke(gApiToken)
    } else if (token) {
        google.accounts.oauth2.revoke(token.access_token)
    }
}
