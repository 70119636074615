import { useState } from 'react'
import styled from 'styled-components'

export const Dropdown = ({ label, selected, options, onChange, className }) => { 
    return (<Control className={className}>
        <label>
            <LabelText>{label}</LabelText>
            <DropdownOption value={selected} onChange={onChange}>
                {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
                ))}
            </DropdownOption>
        </label>
    </Control>)
}

export const InputField = ({ label, type, value, className, ...inputProps }) => { 
    return (<Control className={className}>
        <label>
            <LabelText>{label}</LabelText>
            <input type={type||'text'} value={value} {...inputProps} />
        </label>
    </Control>)
}

export const MemoField = styled(InputField)`
    input {
        width: 80%;    
    }
`

export const RadioField = ({ label, options, value, setValue, hasOther, className, ...props }) => {
    const [otherValue, setOtherValue] = useState("")

    return (<Control className={className}>
        <LabelText>{label}</LabelText>
        <RadioOptions>
        {options.map((option, index) => (
            <RadioOption key={index}>
                <label>
                    <input type="radio"
                        name={label}
                        value={option.value}
                        checked={value === option.value}
                        onChange={e => setValue(e.target.value)}
                        {...props}
                    />{option.label}
                </label>
            </RadioOption>
        ))}
        {hasOther && (
            <RadioOption key='other'>
                <label>
                    <input type="radio"
                        name={label}
                        value="other"
                        checked={otherValue}
                        onChange={() => setValue(otherValue)}
                        {...props}
                    />
                    <input type="text"
                        value={otherValue} 
                        onChange={e => {
                            const val = e.target.value
                            setOtherValue(val)
                            setValue(val)
                        }}
                    />
                </label>
            </RadioOption>
        )}
        </RadioOptions>
    </Control>)
}

const Control = styled.div`
    margin: 10px;
    font-size: .8em;
    vertical-align: top;
`

const LabelText = styled.div`
    width: 140px;
    display: inline-block;
    vertical-align: top;
`

const DropdownOption = styled.select`
    height: 24px;
    padding: 0 5px;
    min-width: 160px;
`

export const RadioOptions = styled.div`
    display: inline-block;
`
const RadioOption = styled.div`
    margin: 0 5px 5px -5px;
    input {
        margin-right: 10px;
    }
`