import { useEffect, useState } from 'react'
import styled from 'styled-components'

import * as sheetsApi from './scripts/sheets'
import { convertToRows } from './scripts/formlogic'
import { RadioField, RadioOptions } from './components/controls'
import CreditCard, {defaultCreditCardState} from './tabs/CreditCard'
import Deposit, { defaultDepositsState } from './tabs/Deposit'
import Check, { defaultCheckState } from './tabs/Check'


const getTodaysDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString().slice(0, 10);
}

const defaultState = {
  date: getTodaysDate(),
}

const FormComponent = ({user}) => {
  const [ state, setState ] = useState({})
  const [options, setOptions] = useState({})
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
      if (!user?.email) {
          return
      }
      const fn = async () => {
          const acctJson = await sheetsApi.getAccounts(user)
          const accounts = acctJson
          const banks = acctJson
            .filter(o => o.type === 'bank')
          const creditCards = acctJson
            .filter(o => o.type === 'credit card')

          const payees = await sheetsApi.getPayees(user)
          const customers = await sheetsApi.getCustomers(user)

          setOptions(options => ({
            ...options,
            accounts,
            banks,
            customers,
            payees,
            creditCards,
          }))
      }
      fn()
  }, [user])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSubmitDisabled(true);
    const [row, err] = convertToRows(state)
    if (err) {
      console.error('FormLogic Error:', err)
      return
    }

    await sheetsApi.addRow({email:user.email}, row)
    setIsSubmitDisabled(false)
    setTxType(state.txType, setState)
  };

  const props = {
    state,
    setState,
    user,
    options,
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Transaction Entry Form</Title>
      <hr />
      <>
        <TxnType {...props} />
        {renderForm(props)}
      </>
      {state?.txType && (
        <FormFooter>
            <button type="submit" disabled={isSubmitDisabled}>Submit</button>
        </FormFooter>
      )}
    </Form>
  );
}

const renderForm = props => {
  switch (props.state.txType) {
    case "check":
      return <Check {...props} />
    case "deposit":
      return <Deposit {...props} />
    case "credit":
      return <CreditCard {...props} />
    default:
      return <></>
  }
}

const TxnType = ({state, setState, user}) => {
  const options = [
    { value: "check", label: "Check" },
    { value: "deposit", label: "Deposit" },
    { value: "credit", label: "Credit" },
  ]

  return (
    <StyledRadioField
      label="Transaction Type:"
      options={options}
      value={state.txType}
      setValue={val => setTxType(val, setState)}
      disabled={!user?.email && !user?.gapi_token}
    />
  )
}

const setTxType = (val, setState) => {
  let stateToAdd
  switch (val) {
    case "deposit":
      stateToAdd = defaultDepositsState
      break
    case "credit":
      stateToAdd = defaultCreditCardState
      break
    case "check":
      stateToAdd = defaultCheckState     
      break
    default:
      stateToAdd = () => ({})
      break
  }
  
  setState({
    ...defaultState,
    ...stateToAdd(),
    txType: val,
  })
}

/* #region Styles */

const Form = styled.form`
  border: 1px;
  color: #333;
  width: 100%;
  box-shadow: 0 3px 9px rgb(0 0 0 / 10%);
  text-align:left;
  padding: 30px;
  margin-top: 10px;
`

const FormFooter = styled.div`
  text-align: center;
`

const Title = styled.h1`
  font-size: 25px;
`

const StyledRadioField = styled(RadioField)`
  display: flex;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  padding: 15px;
  margin-left: -10px;
  margin-bottom: 30px;
  ${RadioOptions} {
    display: flex;
    div {
      margin-right: 30px;
    }
  }
`;

/* #endregion Styles */


export default FormComponent;
