import { APP_SCRIPT_URL } from '../.private_data'
import Cookies from 'js-cookie'

export const addRow = async (token, row) => {
  // await initClient(token)

  const apiKey = Cookies.get('apiKey')
  await fetch(APP_SCRIPT_URL, {
    mode: 'no-cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      apiKey,
      row,
    })
  })
  
  alert('SPREADSHEET UPDATED')
}

export const getAccounts = async (token) => {
  return (await fetchFormOptions(token)).accts
    .map(o => ({value: o.name, label: o.name, type: o.type.toLowerCase()}))
}
export const getPayees = async (token) => {
  return (await fetchFormOptions(token)).payees
    .map(o => ({value: o.name, label: o.name}))
}

export const getCustomers = async (token) => {
  return (await fetchFormOptions(token)).customers
    .map(o => ({value: o.name, label: o.name}))
}

let formOptions = null
const fetchFormOptions = async (token) => {
  if (formOptions) {
    return formOptions
  }

  const apiKey = encodeURIComponent(Cookies.get('apiKey'))
  const resp = await fetch(APP_SCRIPT_URL + `?apiKey=${apiKey}&email=${encodeURIComponent(token.email)}`, {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'text/plain;charset=utf-8',
    }
  }).then(r => r.json())

  formOptions = resp
  return formOptions
}

const initClient = async (token) => {
  /* global gapi */
  await loadGoogleApi()
  await gapi.client.init({
    apiKey: token.access_token,
  })
  await gapi.client.load('sheets', 'v4').then(
    () => console.log('DISCOVER DOC LOADED'),
    err => console.error("Discovery Doc err", err)
  )
}

async function loadGoogleApi() {
  return new Promise((resolve, reject) => {
    gapi.load('client', {
      callback: resolve,
      onerror: reject,
      ontimeout: reject,
      timeout: 10000, // 10 seconds.
      retry: true,
    });
  });
}


